import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import db from "../firebase/firebaseInit";
import { _ } from "core-js";
import jwt_decode from "jwt-decode";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    blogPosts: [],
    postLoaded: null,
    blogHTML: "Write your blog title here...",
    blogTitle: "",
    blogPhotoName: "",
    blogPhotoFileURL: null,
    blogPhotoPreview: null,
    editPost: null,
    user: null,
    profileAdmin: null,
    profileEmail: null,
    profileFirstName: null,
    profileLastName: null,
    profileUsername: null,
    profileId: null,
    profileInitials: null,

    allergies: [],
    diagnosis: [],
    surgeries: [],
    rx: [],
    otc: [],
  },
  getters: {
    blogPostsFeed(state) {
      return state.blogPosts.slice(0, 2);
    },
    blogPostsCards(state) {
      return state.blogPosts.slice(2, 6);
    },
  },
  mutations: {
    addAllergy(state, payload){
      state.allergies.push(payload); //TODO: check for duplicates
    },
    addDiagnosis(state, payload){
      state.diagnosis.push(payload); //TODO: check for duplicates
    },
    addSurgery(state, payload){
      state.surgeries.push(payload); //TODO: check for duplicates
    },
    addRx(state, payload){
      state.rx.push(payload); //TODO: check for duplicates
    },
    addOTC(state, payload){
      state.otc.push(payload); //TODO: check for duplicates
    },


    setIdToken(state, playload) {
      state.token = playload;
    },
    newBlogPost(state, payload) {
      state.blogHTML = payload;
    },
    updateBlogTitle(state, payload) {
      state.blogTitle = payload;
    },
    fileNameChange(state, payload) {
      state.blogPhotoName = payload;
    },
    createFileURL(state, payload) {
      state.blogPhotoFileURL = payload;
    },
    openPhotoPreview(state) {
      state.blogPhotoPreview = !state.blogPhotoPreview;
    },
    toggleEditPost(state, payload) {
      state.editPost = payload;
    },
    setBlogState(state, payload) {
      state.blogTitle = payload.blogTitle;
      state.blogHTML = payload.blogHTML;
      state.blogPhotoFileURL = payload.blogCoverPhoto;
      state.blogPhotoName = payload.blogCoverPhotoName;
    },
    filterBlogPost(state, payload) {
      state.blogPosts = state.blogPosts.filter((post) => post.blogID !== payload);
    },
    updateUser(state, payload) {
      state.user = payload;
    },
    setProfileAdmin(state, payload) {
      state.profileAdmin = payload;
      console.log(state.profileAdmin);
    },
    setProfileInfo(state, payload) {
      state.profileId = payload.id;
      state.profileId = payload.id;
      state.profileFirstName = payload.firstName;
      state.profileLastName = payload.lastName;
      console.log(state.profileId);
    },
    setProfileInitials(state) {
      state.profileInitials =
        state.profileFirstName.match(/(\b\S)?/g).join("") + state.profileLastName.match(/(\b\S)?/g).join("");
    },
    changeFirstName(state, payload) {
      state.profileFirstName = payload;
    },
    changeLastName(state, payload) {
      state.profileLastName = payload;
    },
    changeUsername(state, payload) {
      state.profileUsername = payload;
    },
  },
  actions: {
    //async getCurrentUser({ commit }, user) {
      //const dataBase = await db.collection("users").doc(firebase.auth().currentUser.uid);
      //const dbResults = await dataBase.get();
     // commit("setProfileInfo", dbResults);
      //commit("setProfileInitials");
     // const token = await user.getIdTokenResult();
     // const admin = await token.claims.admin;
     // commit("setProfileAdmin", admin);
    //},
    async getPost({ state }) {
      const dataBase = await db.collection("blogPosts").orderBy("date", "desc");
      const dbResults = await dataBase.get();
      dbResults.forEach((doc) => {
        if (!state.blogPosts.some((post) => post.blogID === doc.id)) {
          const data = {
            blogID: doc.data().blogID,
            blogHTML: doc.data().blogHTML,
            blogCoverPhoto: doc.data().blogCoverPhoto,
            blogTitle: doc.data().blogTitle,
            blogDate: doc.data().date,
            blogCoverPhotoName: doc.data().blogCoverPhotoName,
          };
          state.blogPosts.push(data);
        }
      });
      state.postLoaded = true;
    },


    async getAllergies({commit}){
      const allergiesRef = db.collection("users").doc(this.state.profileId).collection("allergies");
      var querry = allergiesRef.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            var allergyToSave = doc.data();
            commit("addAllergy", allergyToSave);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
    });
    },

    async getDiagnosis({commit}){
      const diagnosisRef = db.collection("users").doc(this.state.profileId).collection("diagnosis");
      var querry = diagnosisRef.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            var diagnosisToSave = doc.data();
            commit("addDiagnosis", diagnosisToSave);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
    });
    },

    async getSurgeries({commit}){
      const surgeriesRef = db.collection("users").doc(this.state.profileId).collection("surgeries");
      var querry = surgeriesRef.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            var surgeryToSave = doc.data();
            commit("addSurgery", surgeryToSave);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
    });
    },

    async getRx({commit}){
      const rxRef = db.collection("users").doc(this.state.profileId).collection("rxs");
      var querry = rxRef.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            var rxToSave = doc.data();
            commit("addRx", rxToSave);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
    });
    },

    async getOTC({commit}){
      const otcRef = db.collection("users").doc(this.state.profileId).collection("otc");
      var querry = otcRef.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            var otcToSave = doc.data();
            commit("addOTC", otcToSave);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
    });
    },


    //HANDLE USER STATES

    
    async getCurrentUser( {commit}, user) { //Grab the current userID from the database if they are authorized.
      console.log(firebase.auth().currentUser.uid);
      const dataBase = await db.collection("users").doc(firebase.auth().currentUser.uid);
      const dbResults = await dataBase.get();
      console.log(dbResults);
      var userToSave = dbResults.data();
      userToSave.id = dbResults.id;
      commit("setProfileInfo", userToSave);
      commit("setProfileInitials");
      const token = await user.getIdTokenResult();
      commit("setIdToken", token.token);
      this.dispatch("verifyUser", token.token);
      
    },

    verifyUser({commit}, token) { //call this function before any changes to db 
      var decoded = jwt_decode(token);
      var now = parseInt(new Date().getTime().toString().substring(0,10));
      if (decoded.aud != "med-binder-main" ||
        decoded.auth_time > now ||
        decoded.exp < now) {
          if (this.state.user) {
            firebase.auth().signOut();
            window.location.reload();
            alert("Timed out to protect your information. Log in again.");
          }
          commit("clearUser");
      }
      this.dispatch("getAllergies"); //If the user gets verified, then we can retrieve their data.
      this.dispatch("getDiagnosis");
      this.dispatch("getSurgeries");
      this.dispatch("getRx");
      this.dispatch("getOTC");
    },

    clearUser(state) {
      state.user = null;
      state.profileId = null;
      state.profileFirstName = null;
      state.profileLastName = null;
      state.token = null;
    },

    async updatePost({ commit, dispatch }, payload) {
      commit("filterBlogPost", payload);
      await dispatch("getPost");
    },
    async deletePost({ commit }, payload) {
      const getPost = await db.collection("blogPosts").doc(payload);
      await getPost.delete();
      commit("filterBlogPost", payload);
    },
    async updateUserSettings({ commit, state }) {
      const dataBase = await db.collection("users").doc(state.profileId);
      await dataBase.update({
        firstName: state.profileFirstName,
        lastName: state.profileLastName,
        username: state.profileUsername,
      });
      commit("setProfileInitials");
    },


    //ADDING PROFILE DATA TO DATABASE


    async saveAllergyToDB({commit}, allergy){
      var allergytosave = {
        name: allergy.name,
        severity: allergy.severity
      }
      const allergiesRef = db.collection("users").doc(this.state.profileId).collection("allergies").doc();
      await allergiesRef.set(allergytosave);
      commit("addAllergy", allergytosave);
    },

    async saveDiagnosisToDB({commit}, diagnosis){
      var diagnosisToSave = {
        name: diagnosis.name
      }
      const diagnosisRef = db.collection("users").doc(this.state.profileId).collection("diagnosis").doc();
      await diagnosisRef.set(diagnosisToSave);
      commit("addDiagnosis", diagnosisToSave);
    },

    async saveSurgeryToDB({commit}, surgery){
      var surgeryToSave = {
        name: surgery.name,
        year: surgery.year
      }
      const surgeriesRef = db.collection("users").doc(this.state.profileId).collection("surgeries").doc();
      await surgeriesRef.set(surgeryToSave);
      commit("addSurgery", surgeryToSave);
    },

    async saveRxToDB({commit}, rx){
      var rxToSave = {
        name: rx.name
      }
      const rxRef = db.collection("users").doc(this.state.profileId).collection("rxs").doc();
      await rxRef.set(rxToSave);
      commit("addRx", rxToSave);
    },

    async saveOTCToDB({commit}, otc){
      var otcToSave = {
        name: otc.name
      }
      const otcRef = db.collection("users").doc(this.state.profileId).collection("otc").doc();
      await otcRef.set(otcToSave);
      commit("addOTC", otcToSave);
    },




  },
  modules: {},
});
