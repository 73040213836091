
<template>
  <div class="home">
    <BlogPost v-if="!user" :post="welcomeScreen" />
     
    
    
 
      <div v-if="user" >
        
        
          <!-- START CARD-->
        <div class="profile">
          
          


          <div class="container">
            <h2>Welcome back, {{ $store.state.profileFirstName }}</h2>
            <p>This is your personalized dashboard.</p>
              <div class="profile-info">
                
              
                
                

                <div class="input">

                  <!-- GENERALS -->
                  <div class="card text-center align-items-center" style="width: auto; padding-top: 15px;">
                  <div class="initials">{{ $store.state.profileInitials }}</div>
                  <h2 class="card-title">{{ $store.state.profileFirstName}} {{$store.state.profileLastName}}</h2>
                  
                  <div class="card-body">
                    <ul class="list-group list-group-horizontal-sm"> <!-- Fix this later -->
                      <li class="list-group-item">
                        Date of Birth
                        <ul class="list-group">11/11/1111</ul>
                      </li>
                      <li class="list-group-item">
                        Height
                        <ul class="list-group">1'11"</ul>
                      </li>
                      <li class="list-group-item">
                        Weight
                        <ul class="list-group">111 lbs</ul>
                        </li>
                      <li class="list-group-item">
                        BP
                        <ul class="list-group">111/11</ul>
                        </li>
                      <li class="list-group-item">
                        Blood Type
                        <ul class="list-group">O-</ul>
                        </li>
                    </ul>
                    </div>
                </div>


                    <!--Next Appointment / Primary Doctor -->
                    <ul class="list-group list-group-horizontal">
                    <div class="card text-center align-items-center" style="width: 18rem; padding-top: 10px;">
                      <h5 class="card-title">Next Appointment</h5>
                          <p>
                        Dr. Other
                      </p>
                      <p>
                        11/11/1111 - 11:11 AM
                      </p>
                      <p>
                        123 Drive Pl.
                      </p>
                    </div>

                    <div class="card text-center align-items-center" style="width: 18rem; padding-top: 10px;">
                      <h5 class="card-title">Primary Doctor</h5>
                      <p>
                        Dr. Person
                      </p>
                      <p>
                        (123)-456-7890
                      </p>
                      <p>
                        123 Drive Pl.
                      </p>

                     
                    </div>
                     </ul>

                    


                  <!-- Allergies -->
                  <div class="card text-center align-items-center" style="width: 100%; padding-top: 10px;">
                    <div class="card-body">
                      <h5 class="card-title">Allergies</h5>
                    <ul class="list-group list-group-horizontal"> <!-- Fix this later -->
                     

                    <!-- FOR GOES IN HERE -->
                    <div v-for="(allergy, index) in allergies" :key="index"> 
                    <li class="list-group-item" :allergy="allergy" :key="allergy.id">{{allergy.name}}

                      <ul class="list-group">{{allergy.severity}}</ul>

                    </li>
                    </div>



                    </ul>
                    <button data-bs-toggle="modal" data-bs-target="#addAllergy">Add Allergy</button>
                    </div>
                  </div>
                    

                          <!-- Add Allergy Modal -->
                          <div class="modal fade" id="addAllergy" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="exampleModalLabel">Add a New Allergy</h5>
                                  <!--<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"> 
                                    <span aria-hidden="true">&times;</span>
                                  </button>-->
                                </div>
                                <div class="modal-body">
                                  <!--Please Enter Allergy Below -->
                                  <input class="form-control" v-model="allergy.name" aria-describedby="emailHelp" placeholder="Enter Allergy">
                                  <small id="emailHelp" class="form-text text-muted">We'll never share your information with anyone else.</small>
                                  <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <label class="input-group-text" for="inputGroupSelect01">Severity</label>
                                  </div>
                                  <select class="custom-select" v-model="allergy.severity" id="inputGroupSelect01">
                                    <option selected>Choose...</option>
                                    <option value="1">Mild</option>
                                    <option value="2">Moderate</option>
                                    <option value="3">Severe</option>
                                  </select>
                                </div>
                                <div
                                            ref="messages"
                                            v-if="errorMessages.length"
                                            class="alert alert-danger fade show"
                                            >
                                            <span
                                                v-for="(msg, index) in errorMessages"
                                                :key="index"
                                                id="message"
                                                v-html="msg"
                                            ></span>
                                            <button
                                                type="button"
                                                class="btn-close"
                                                @click="errorMessages = []"
                                                data-bs-dismiss="alert"
                                                aria-label="Close"
                                            ></button>
                                            </div>

                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                  <button type="button" class="btn btn-primary" @click="saveAllergy()">Add to Profile</button>
                                </div>
                              </div>
                            </div>
                          </div>


                <!-- Diagnosis -->
                  <div class="card text-center align-items-center" style="width: 100%; padding-top: 10px;">
                    <div class="card-body">
                      <h5 class="card-title">Diagnosis</h5>
                    <ul class="list-group list-group-horizontal"> <!-- Fix this later -->


                     <div v-for="(diagnosis, index) in diagnosis" :key="index"> 
                    <li class="list-group-item" :diagnosis="diagnosis" :key="diagnosis.id">{{diagnosis.name}}</li>
                    </div>



                    </ul>
                    <button data-bs-toggle="modal" data-bs-target="#addDiagnosis">Add Diagnosis</button>
                    </div>
                  </div>


                <!-- Add Diagnosis Modal -->
                          <div class="modal fade" id="addDiagnosis" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="exampleModalLabel">Add a New Diagnosis</h5>
                                </div>
                                <div class="modal-body">
                                  <!--Please Enter Diagnosis Below -->
                                  <input class="form-control" v-model="diagnosis.name" aria-describedby="emailHelp" placeholder="Enter Diagnosis">
                                  <small id="emailHelp" class="form-text text-muted">We'll never share your information with anyone else.</small>
                                  <div class="input-group mb-3">
                                 
                                </div>


                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                  <button type="button" class="btn btn-primary" @click="saveDiagnosis()">Add to Profile</button>
                                </div>
                              </div>
                            </div>
                          </div>




                <!-- Surgeries -->
                  <div class="card text-center align-items-center" style="width: 100%; padding-top: 10px;">
                    <div class="card-body">
                      <h5 class="card-title">Surgeries</h5>
                    <ul class="list-group list-group-horizontal"> <!-- Fix this later -->
                      <!-- FOR GOES IN HERE -->
                    <div v-for="(surgery, index) in surgeries" :key="index"> 
                    <li class="list-group-item" :surgery="surgery" :key="surgery.id">{{surgery.name}}

                      <ul class="list-group">{{surgery.year}}</ul>

                    </li>
                    </div>
                    </ul>
                    <button data-bs-toggle="modal" data-bs-target="#addSurgery">Add Surgery</button>
                    </div>
                  </div>


                <!-- Add Surgery Modal -->
                          <div class="modal fade" id="addSurgery" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="exampleModalLabel">Add a New Surgery</h5>
                                </div>
                                <div class="modal-body">
                                  <!--Please Enter Surgery Below -->
                                  <input class="form-control" v-model="surgery.name" aria-describedby="emailHelp" placeholder="Enter Surgery">
                                  <small id="emailHelp" class="form-text text-muted">We'll never share your information with anyone else.</small>
                                  <input class="form-control" v-model="surgery.year" aria-describedby="emailHelp" placeholder="Enter Year of Surgery">


                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                  <button type="button" class="btn btn-primary" @click="saveSurgery()">Add to Profile</button>
                                </div>
                              </div>
                            </div>
                          </div>




                <!-- Rx -->
                  <div class="card text-center align-items-center" style="width: 100%; padding-top: 10px;">
                    <div class="card-body">
                      <h5 class="card-title">Rx</h5>
                    <ul class="list-group list-group-horizontal"> <!-- Fix this later -->
                      
                     <div v-for="(rx, index) in rx" :key="index"> 
                    <li class="list-group-item" :rx="rx" :key="rx.id">{{rx.name}}</li>
                    </div>




                    </ul>
                    <button data-bs-toggle="modal" data-bs-target="#addRx">Add Rx</button>
                    </div>
                  </div>

                    <!-- Add Rx Modal -->
                          <div class="modal fade" id="addRx" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="exampleModalLabel">Add a New Rx</h5>
                                </div>
                                <div class="modal-body">
                                  <!--Please Enter Surgery Below -->
                                  <input class="form-control" v-model="rx.name" aria-describedby="emailHelp" placeholder="Enter Rx">
                                  <small id="emailHelp" class="form-text text-muted">We'll never share your information with anyone else.</small>
                                  


                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                  <button type="button" class="btn btn-primary" @click="saveRx()">Add to Profile</button>
                                </div>
                              </div>
                            </div>
                          </div>














                <!-- OTC Medication -->
                  <div class="card text-center align-items-center" style="width: 100%; padding-top: 10px;">
                    <div class="card-body">
                      <h5 class="card-title">OTC Medication</h5>
                    <ul class="list-group list-group-horizontal"> <!-- Fix this later -->
                      <li class="list-group-item">
                        Date of Birth
                        <ul class="list-group">11/11/1111</ul>
                      </li>
                      <li class="list-group-item">
                        Height
                        <ul class="list-group">1'11"</ul>
                      </li>
                      <li class="list-group-item">
                        Weight
                        <ul class="list-group">111 lbs</ul>
                        </li>
                      <li class="list-group-item">
                        BP
                        <ul class="list-group">111/11</ul>
                        </li>
                      <li class="list-group-item">
                        Blood Type
                        <ul class="list-group">O-</ul>
                        </li>
                    </ul>
                    <button>Add OTC</button>
                    </div>
                  </div>

                  <div class="profile-info"> Notes   <div class="profile-info-cont"> Med 2 </div><button>Add Note</button> </div>
                </div>
                
                






              </div>
            </div>
          </div>
          <!-- END CARD -->


      </div>

    <div v-if="!user" class="updates">
      <div class="container">
        <h2>Get organized. <pre>Stay Organized.</pre><pre>Sign up free today.</pre></h2>
        <router-link class="router-button" to="register"> Register for Med Binder <Arrow class="arrow arrow-light" /> </router-link>
      </div>
    </div>

  </div>
</template>

<script>
import BlogPost from "../components/BlogPost";
import { mapState } from "vuex";
import Arrow from "../assets/Icons/arrow-right-light.svg";

export default {

  created(){
    const tempObj = {};
    this.allergy = this.allergy != null ? tempObj : {};
    //this.diagnosis = this.diagnosis != null ? tempObj : {};
    this.surgery = this.surgery != null ? tempObj : {};
    this.rx = this.rx != null ? tempObj : {};
    
    
  },

  name: "Home",
  components: { BlogPost,  Arrow, },
  data() {
    return {
      welcomeScreen: {
        title: "Welcome!",
        blogPost:
          "Get organized with Med Binder. Keep all your meds, notes, appointments, etc. all in one handy place.",
        welcomeScreen: true,
        photo: "binder",
      },



      errorMessages: [],
    };
  },
  computed: {
    ...mapState(["allergies","diagnosis","surgeries","rx"]),
      firstName: {
        get() {
          return this.$store.state.profileFirstName;
        },
      },

    blogPostsFeed() {
      return this.$store.getters.blogPostsFeed;
    },
    blogPostsCards() {
      return this.$store.getters.blogPostsCards;
    },
    user() {
      return this.$store.state.user;
    },
    
  },

methods:{
  async saveAllergy(){
    this.errorMessages = [];
    const reg = /^[a-zA-Z ]{1,15}$/;



    //Determines the severity and checks for errors
    if(this.allergy.severity == 3){
      this.allergy.severity = "Severe";
    } else if(this.allergy.severity == 2){
      this.allergy.severity = "Moderate";
    } else if(this.allergy.severity == 1){
      this.allergy.severity = "Mild";
    } else{
      this.errorMessages.push("<b>Severity</b> is required.\n");
    }

    if(!reg.test(this.allergy.name) || this.allergy.name == null){
      this.errorMessages.push("<b>Invalid name<b>.\n");
    }


    if(this.errorMessages.length == 0){
    this.$store.dispatch("saveAllergyToDB", Object.assign({},this.allergy));
    }
  },
   async saveDiagnosis(){
     const reg = /^(19|20)\d{2}$/; //Regular expression to determine a valid year
    this.$store.dispatch("saveDiagnosisToDB", Object.assign({},this.diagnosis));
  },
   async saveSurgery(){
    this.$store.dispatch("saveSurgeryToDB", Object.assign({},this.surgery));
  },
  async saveRx(){
    this.$store.dispatch("saveRxToDB", Object.assign({},this.rx));
  }

}

};
</script>

<style lang="scss" scoped>
.card{

      margin: 0 auto;
      float: none;
      margin-bottom: 10px;
      
      //.list-group{
        //list-style-position: inside;
     // }

    }


.container {
    max-width: 100%;
    padding: 60px 25px;
    p{
      text-align: center;
    }

    h2 {
      text-align: center;
      margin-bottom: 16px;
      font-weight: 300;
      font-size: 32px;
    }

  

    .profile-info {
      border-radius: 8px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      padding: 32px;
      background-color: #f1f1f1;
      display: flex;
      flex-direction: column;
      max-width: 600px;
      margin: 32px auto;

      .initials {
        position: initial;
        width: 80px;
        height: 80px;
        font-size: 32px;
        background-color: #303030;
        color: #fff;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      

    
    }



  
  }
</style>
