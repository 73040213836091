<template>
  <div class="about">
    <p>I’ve been told many times, “If I ever get sick or my loved one does, I want you to go with me to the Dr.”</p>
    <p>WHAT? Me? Why???  I realized however, that my journey with my husband’s four cancers has given me a unique experience to draw from. </p>
    <p>
In the year 2000 I was a 26 year old, new home owner, new business owner, a newlywed and, three months pregnant. Eight months in to married life I was cautiously optimistic about this pregnancy, 
as I had miscarried our honeymoon baby, when WHAMMY, my husband was diagnosed with an aggressive sarcoma cancer. Like all tragedies and illness, they strike like a thief in the night, taking you off guard.</p>
<p>
 I did the only thing my younger self could do. I took notes and I kept everything; I mean EVERYTHING.  I made different colored binders to keep all the notes and expenses of the miscarriage, the pregnancy and cancer treatments straight, not to
  mention the house projects and the business!  That is truly when my journey to what is now the Med Binder began.  </p>
  <p>
In 2012 my husband was diagnosed with a Lymphoma that even after aggressive treatment, it came back.  In 2014, he had to have a bone marrow transplant. And thank you Lord, so far, it has worked.  All the while I kept notes and notebooks; documenting everything I could. 
In 2018 the original cancer came back.  EIGHTEEN years later!  His original oncology Dr had retired, and papers and notes had been purged.  But because I kept such copious notes and copies of reports, I was able to give his Drs the information they needed.
December of 2019, my father was diagnosed with cancer. I was cocky.  I was like dad, I got you.  I was so naive.  I didn’t know his medical history like I did my husbands.  I didn’t know his allergies, his surgeries, his medication list.  I felt cut off at the knees that I wasn’t prepared to be his advocate. But I gave him a notebook and set it up for him to keep track of everything.  
Then the 2020 pandemic hit!  I could no longer go with my dad OR my husband to appointments.  People all over, were going solo to retain information that was life altering.  I realized then and there, we needed more than a tangible Med Binder, we needed an online documentation of medical history, current appointments, Notes from each appointment, medication lists etc, that we could share and keep track of. 
So, through the years of doctors’ visits, cancer treatments, hospital stays, and caregiver experience I’ve created Med Binder so that you too, can keep track of anything that might come your way!
It is my fervent prayer and wish is that no matter your age or stage of life. Weather you be a student off to college, doing life single, newlyweds, busy parents, middle aged or living out your golden years, that Med Binder helps you stay in control of your important health documents and history.   In a crazy busy world, we live in, may this area of your life be something you have under control.  I hope I cannot just help my family and friends but that Med Binder might help YOU and YOUR loved ones. 
Blessings, 
Laura Lykins
CEO Med Binder
PS….my husband is alive and well, currently cancer free and my dad is doing great!!
</p>
  </div>
</template>

<script>
export default {
 name: "About",
};
</script>

<style lang="scss">
.post-view {
  min-height: 100%;

  .container {
    max-width: 1000px;
    padding: 60px 25px;
  }

  .ql-editor {
    padding: 0;
  }

  h2 {
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 32px;
  }

  img {
    width: 100%;
    margin-bottom: 32px;
  }
}
</style>
