import firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyAGZGDd78_Cf6X58MRL320UxsoRj20Bei8",
  authDomain: "med-binder-main.firebaseapp.com",
  projectId: "med-binder-main",
  storageBucket: "med-binder-main.appspot.com",
  messagingSenderId: "191529461781",
  appId: "1:191529461781:web:6078838ebd0658e90fc61c",
  measurementId: "G-XXJCV3B5PF"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { timestamp };
export default firebaseApp.firestore();
