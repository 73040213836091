<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-success">
  <div class="container-fluid">
    <a class="navbar-brand" href="./">(*Logo*) Med Binder</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="./">Home</a>
        </li>

        <li v-if="!user" class="nav-item">
          <a class="nav-link active" aria-current="page" href="Login">Sign in</a>
        </li>


        <li v-if="user" class="nav-item">
          <a class="nav-link" href="./" @click="signOut">Sign Out</a>
        </li>

        
        <li v-if="user" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Profile
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="./Profile">Edit Profile</a></li>
            <!--<li v-if="admin"><a class="dropdown-item" href="./BlogPost">Create News Post</a></li>-->
            <!--<li v-if="admin"><a class="dropdown-item" href="./Admin">Administration</a></li> -->
          </ul>
        </li>

        <li v-if="user" class="nav-item">
          <a class="nav-link" aria-current="page" href="./About">About</a>
        </li>

        <li v-if="user" class="nav-item">
          <a class="nav-link" aria-current="page" href="./News">News</a>
        </li>



      </ul>
    </div>
  </div>
</nav>
</template>

<script>

import firebase from "firebase/app";

export default {

    methods:{
        signOut() {
            firebase.auth().signOut();
            window.location.reload();
        },
    },


    computed: {
        //Return the current user session
        user() {
        return this.$store.state.user;
        },


    }
}
</script>


<style lang="scss" scoped>
    .navbar {
        padding-left: 1rem;
        
    }
    li {
        padding-left: 1rem;
        
        
        
    }
    .user {
        align-content: right;
    }

    .nav-item active{
        &:hover{
            color: rgba(255, 255, 255, 0.384);
        }
    }


</style>